import './App.css';
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

const MIN_A = 0
const MAX_A = 10

const MIN_B = 0
const MAX_B_INITIAL = 5
const MIN_ANSWER = 10

function getRandomKey(map, minAnswer) {
  console.log('getRandomKey', minAnswer)
  let keys = Array.from(map.keys());
  let randomKey = keys[Math.floor(Math.random() * keys.length)];

  while (map.get(randomKey) <= minAnswer) {
    randomKey = keys[Math.floor(Math.random() * keys.length)];
  }

  return randomKey
}

const getTable = (maxB = MAX_B_INITIAL) => {
  const table = new Map()

  /*
  a * b = x

  a = x / b
  b = x / a
  * */

  for (let i = MIN_A; i <= MAX_A; i++) {
    for (let k = MIN_B; k <= maxB; k++) {
      const key = `${i} * ${k}`
      const val = (i * k).toString()
      // table[key] = val
      table.set(key, val)
    }
  }

  return table
}

const ControlledSelect = ({onChange, value, options, className}) => (
  <select
    value={value}
    onChange={onChange}
    className={className}
  >
    {Object.keys(options).map(o => (
      <option key={o} value={o}>{options[o]}</option>
    ))}
  </select>
)

const ChallengeType = Object.freeze({
  DIVIDE: 'DIVIDE',
  DIVIDE_BY_X: 'DIVIDE_BY_X',
  X_DIVIDE: 'X_DIVIDE',
})

const Operation = Object.freeze({
  ' : ': ' : ',
  ' * ': ' * ',
})

function App() {

  const [minAnswer, setMinAnswer] = useState(MIN_ANSWER)
  const [maxB, setMaxB] = useState(MAX_B_INITIAL)
  const [operation, setOperation] = useState(Operation[' : '])
  const table = getTable(maxB)
  const randomKeyInitial = getRandomKey(table, minAnswer)

  const [randomKey, setRandomKey] = useState(randomKeyInitial)

  const [answer, setAnswer] = useState('')
  const [isCorrect, setIsCorrect] = useState(false)
  const [type, setType] = useState(ChallengeType.DIVIDE_BY_X)
  const childRef = useRef()

  const correctAnswer = table.get(randomKey)
  const [arg1, arg2] = randomKey.split(' * ')

  const focusInput = () => {
    childRef.current?.focus()
  }

  useEffect(() => {
    focusInput()
  }, []);

  let inputClassName = ''
  if (answer !== '') {
    if (isCorrect) {
      inputClassName = 'correct'
    } else {
      inputClassName = 'incorrect'
    }
  }

  let calcCorrect
  const onInputChange = e => {
    const a = e.target.value
    setAnswer(a)
    setIsCorrect(false)
    if (calcCorrect(a)) {
      setIsCorrect(true)
      setTimeout(() => {
        setRandomKey(getRandomKey(table, minAnswer))
        setAnswer('')
      }, 750)
    }
  }

  const onInputKeyUp = e => {
    if (e.key === 'Enter') {
      setRandomKey(getRandomKey(table, minAnswer))
      setAnswer('')
    }
  }

  const OperationSelect = (
    <ControlledSelect
      onChange={e => setOperation(e.target.value)}
      value={operation}
      options={Operation}
      className={'big pl pr'}
    />
  )

  let Before = () => null
  let After = () => null

  let first, second
  switch (type) {
    case ChallengeType.X_DIVIDE:
      switch (operation) {
        case Operation[' * ']:
          calcCorrect = a => a === arg1
          first = arg2
          second = correctAnswer
          break

        case Operation[' : ']:
        default:
          calcCorrect = a => a === correctAnswer
          first = arg2
          second = arg1
          break
      }

      After = () => (
        <div className={'big inline'}>
          {OperationSelect}
          <span>{first}</span>
          <span>{` = `}</span>
          <span>{second}</span>
        </div>
      )
      break

    case ChallengeType.DIVIDE_BY_X:
      switch (operation) {
        case Operation[' * ']:
          calcCorrect = a => a === arg2
          first = arg1
          second = correctAnswer
          break

        case Operation[' : ']:
        default:
          calcCorrect = a => a === arg2
          first = correctAnswer
          second = arg1
          break
      }

      Before = () => (
        <div className={'big inline'}>
          <span>{first}</span>
          {OperationSelect}
        </div>
      )
      After = () => (
        <div className={'big inline'}>
          <span>{` = `}</span>
          <span>{second}</span>
        </div>
      )
      break

    case ChallengeType.DIVIDE:
    default:
      switch (operation) {
        case Operation[' * ']:
          calcCorrect = a => a === correctAnswer
          first = arg1
          second = arg2
          break

        case Operation[' : ']:
        default:
          calcCorrect = a => a === arg2
          first = correctAnswer
          second = arg1
          break
      }

      Before = () => (
        <div className={'big inline'}>
          <span>{first}</span>
          {OperationSelect}
          <span>{second}</span>
          <span>{` = `}</span>
        </div>
      )
      break
  }

  return (
    <div>
      <div>
        type:
        <ControlledSelect
            onChange={e => setType(e.target.value)}
            value={type}
            options={ChallengeType}
        />
        max b:
        <input
          onChange={e => setMaxB(parseInt(e.target.value))}
          value={maxB}
          type="number"
          min={MAX_B_INITIAL}
          max={10}
        />

        min answer:
        <input
          type="number"
          value={minAnswer}
          onChange={e => setMinAnswer(parseInt(e.target.value))}
          min={0}
          max={maxB*10}
        />
        <div>
          <button onClick={() => {
            setRandomKey(getRandomKey(table, minAnswer))
            setAnswer('')
            focusInput()
          }}>New Game
          </button>
        </div>

        <Before/>
        <input
          ref={childRef}
          onKeyUp={onInputKeyUp}
          onChange={onInputChange}
          className={`${inputClassName} answer big`}
          value={answer}
          type="text"
        />
        <After/>
      </div>
    </div>
  );
}

export default App;
